











































import {Component, Ref, Vue} from "vue-property-decorator";
import StringTool from "@/services/tool/StringTool";
import LoginService from "@/services/LoginService";
import SessionModule from "@/store/SessionModule";
import {getModule} from "vuex-module-decorators";
import SnackbarModule from "@/store/SnackbarModule";
import Session from "@/models/Session";
import ProfileTool from "@/services/tool/ProfileTool";

@Component
export default class LoginView extends Vue {
    @Ref() readonly form!: HTMLFormElement
    @Ref() readonly loginButton!: HTMLButtonElement
    sessionModule: SessionModule = getModule(SessionModule)
    email: string = ""
    password: string = ""
    showPassword: boolean = false
    progress: boolean = false
    loginButtonReset = 1

    emailRules = [
        (v: string) => v.length > 0 ? true : "Email requerido",
        (v: string) => StringTool.validateEmail(v) ? true : "Email no es válido"
    ]

    passwordRules = [
        (v: string) => v.length > 0 ? true : "Contraseña requerida"
    ]

    created() {
        if (ProfileTool.isDev()) {
            // this.email = "admin@ieseuropa.com"
            // this.password = "1234"
        }

        this.sessionModule.setSession(new Session());
        this.sessionModule.saveSession()
    }

    login() {
        if (this.form.validate()) {
            LoginService.postLogin(this, this.email, this.password, getModule(SnackbarModule), this.sessionModule)
        }
    }

    count: number = 0

    developLogin() {
        this.count++
        if (this.count >= 3) {
            this.email = "admin@ieseuropa.es"
            this.count = 0
        }
    }
}
